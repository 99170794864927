import React from "react";

export interface PatientInformation {
  firstName?: string;
  lastName?: string;
  DOB?: string;
  gender?: string;
}
interface IAuthCodeContext {
  code: string;
  setCode: (newCode: string) => void;
  patientInformation: PatientInformation;
  setPatientInformation: (newPatientInformation: PatientInformation) => void;
}

const AuthCodeContext = React.createContext<IAuthCodeContext | null>(null);

export function useAuthCode(): IAuthCodeContext {
  const context = React.useContext(AuthCodeContext);
  if (!context) {
    throw new Error("useAuthCode must be used within an AuthCodeProvider");
  }
  return context as IAuthCodeContext;
}

export function AuthCodeProvider({ children }) {
  const [code, setCode] = React.useState("");
  const [patientInformation, setPatientInformation] = React.useState({
    // firstName: "",
    // lastName: "",
    // DOB: "",
    // gender: "",
  });
  return (
    <AuthCodeContext.Provider
      value={{ code, setCode, patientInformation, setPatientInformation }}
    >
      {children}
    </AuthCodeContext.Provider>
  );
}
