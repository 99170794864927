import { Checkbox, Stack } from "@chakra-ui/react";
import { IMedspecField } from "@packages/types";
import { useFormState } from "contexts/FormState";

import { useValidation } from "../../hooks/useValidation";

import { fieldId } from "./fieldId";

export function MultiChoiceField({
  id,
  choices,
  validators,
  name,
}: IMedspecField) {
  // Context for storing form information and validity states
  const { form, setFormState } = useFormState();
  const { isInvalid } = useValidation(id, validators);

  const handleSelectionChanges = (
    original: string[] | undefined,
    choice: string,
    checked: boolean,
  ) => {
    if (original === undefined && checked) {
      // First add
      return [choice];
    } else if (original === undefined) {
      // Init
      return [];
    } else if (original.includes(choice) && !checked) {
      // Remove
      return original.filter((existing) => choice !== existing);
    } else if (checked) {
      // Add (use concat so no clone required)
      return original.concat([choice]);
    }
    return original;
  };

  const handleUpdate = (choice: string, checked: boolean) => {
    setFormState((prev) => {
      const updatedArray = handleSelectionChanges(prev[id], choice, checked);
      return { ...prev, [id]: updatedArray };
    });
  };

  return (
    <Stack my={4} ml={2} id={`${fieldId({ name, id })}`}>
      {choices?.map((choice, key) => (
        <Checkbox
          isInvalid={isInvalid}
          data-is-invalid={isInvalid}
          key={key}
          isChecked={form[id]?.includes(choice)}
          onChange={(e) => handleUpdate(choice, e.target.checked)}
        >
          {choice}
        </Checkbox>
      ))}
    </Stack>
  );
}
