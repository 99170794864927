import { Select } from "@chakra-ui/react";
import { IMedspecField } from "@packages/types";
import { useFormState } from "contexts/FormState";

import { useValidation } from "../../hooks/useValidation";

import { fieldId } from "./fieldId";

export function DropdownField({
  id,
  choices,
  validators,
  name,
}: IMedspecField) {
  // Context for storing form information and validity states
  const { form, setFormState } = useFormState();
  const { isInvalid } = useValidation(id, validators);

  const handleUpdate = (newValue: string) => {
    setFormState((prev) => ({ ...prev, [id]: newValue }));
  };

  return (
    <Select
      id={`${fieldId({ name, id })}`}
      isInvalid={isInvalid}
      data-is-invalid={isInvalid}
      value={form[id] ?? "None"}
      onChange={(event) => handleUpdate(event.target.value)}
    >
      <option disabled hidden value={"None"}></option>
      {choices?.map((value, key) => (
        <option key={key} value={value}>
          {value}
        </option>
      ))}
    </Select>
  );
}
