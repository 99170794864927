import { Key, ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { Button, HStack, Text } from "@chakra-ui/react";
import { IForm } from "@packages/types";
import { useAlert } from "contexts/AlertState";
import { useAuthCode } from "contexts/AuthCode";
import { useFormState } from "contexts/FormState";
import { useValidationState } from "contexts/ValidationState";
import { useSaveSlideMutation } from "http/hooks";

import { Card, CardFooter, CardTitle } from "components/Card";
import FormField from "components/fields";

interface IRenderSlideProps {
  schema: IForm;
  slide: any;
}

export function Slide({ schema, slide }: IRenderSlideProps): ReactElement {
  const navigate = useNavigate();
  const { valid, setValidationState } = useValidationState();
  const { form } = useFormState();
  const { code } = useAuthCode();
  const { showError } = useAlert();

  // Check if the slide is fully valid and save if it is
  const isSlideValid = (): boolean =>
    !slide.fields?.some(({ id }) => !valid[id]);

  const saveSlideMutation = useSaveSlideMutation({
    code,
    slide,
    form,
    schema,
    showError,
    navigate,
  });
  return (
    <Card>
      <CardTitle>{slide.name}</CardTitle>
      {slide.description && (
        <Text fontStyle="italic">
          {
            // replace newlines with <br /> tags
            slide.description.split("\n").map((item: string, key: Key) => (
              <span key={key}>
                {item}
                <br />
              </span>
            ))
          }
        </Text>
      )}
      <div>
        {slide.fields?.map((field, key) => (
          <FormField field={field} key={key} />
        ))}
      </div>
      <CardFooter>
        <HStack justify="space-between">
          <Button
            onClick={() => {
              navigate(-1);
            }}
            colorScheme={"red"}
          >
            Back
          </Button>
          <Button
            onClick={() => {
              setValidationState((prev) => ({
                ...prev,
                emptyForm: false,
              }));
              if (isSlideValid()) {
                saveSlideMutation.mutate();
                setValidationState((prev) => ({
                  ...prev,
                  emptyForm: true,
                }));
              }
            }}
            isDisabled={!valid?.emptyForm && !isSlideValid()}
            colorScheme={"brand"}
            isLoading={saveSlideMutation.isLoading}
          >
            Next
          </Button>
        </HStack>
      </CardFooter>
    </Card>
  );
}
