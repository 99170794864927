import { ReactElement, useEffect } from "react";
import { Input } from "@chakra-ui/input";
import { IMedspecField } from "@packages/types";
import { useFormState } from "contexts/FormState";

import { useAuthCode } from "../../contexts/AuthCode";
import { useValidation } from "../../hooks/useValidation";

import { fieldId } from "./fieldId";

const isFirstName = (str: string): boolean => {
  const name = str.toLowerCase().replace(/\s/g, "");
  return name.includes("firstname") || name.includes("givenname");
};

const isLastName = (str: string): boolean => {
  const name = str.toLowerCase().replace(/\s/g, "");
  return (
    name.includes("lastname") ||
    name.includes("surname") ||
    name.includes("familyname")
  );
};

export function TextField({
  id,
  validators,
  placeholder,
  name,
}: IMedspecField): ReactElement {
  const { form, setFormState } = useFormState();
  const { patientInformation } = useAuthCode();
  const { isInvalid } = useValidation(id, validators);

  const handleUpdate = (newValue: string): void => {
    setFormState((prev) => ({ ...prev, [id]: newValue }));
  };

  useEffect(() => {
    if (!form[id] && isFirstName(name) && patientInformation.firstName) {
      handleUpdate(patientInformation.firstName);
    }
    if (!form[id] && isLastName(name) && patientInformation.lastName) {
      handleUpdate(patientInformation.lastName);
    }
  }, [patientInformation]);

  return (
    <Input
      mt={1}
      px={3}
      py={2}
      id={`${fieldId({ name, id })}`}
      type="text"
      value={form[id] ?? ""}
      isInvalid={isInvalid}
      data-is-invalid={isInvalid}
      placeholder={placeholder ?? ""}
      onChange={(event) => handleUpdate(event.target.value)}
    />
  );
}
