import { CircularProgress, Flex, Text } from "@chakra-ui/react";

import { Card } from "./Card";

export function LoadingCard({ message }: { message: string }) {
  return (
    <Card>
      <Flex align="center" justify="center">
        <CircularProgress
          my={8}
          size={16}
          isIndeterminate
          thickness="16px"
          color="black"
        />
      </Flex>
      <Text fontSize="2xl" textAlign="center" mb={8}>
        {message}
      </Text>
    </Card>
  );
}
