interface ILinkProps {
  children: React.ReactNode;
  onClick: () => void;
}

export default function Link({ children, onClick }: ILinkProps) {
  return (
    <span
      style={{
        display: "inline",
        color: "blue",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      {children}
    </span>
  );
}
