import React from "react";
import {
  Button,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export interface IModalProps {
  isOpen: boolean;
  onClose: () => void;
  name?: string;
  children?: React.ReactNode;
  setPolicyOpen: (bool) => void;
}
import Link from "./Link";

export function TermsOfUseModal({
  isOpen,
  onClose,
  setPolicyOpen,
}: IModalProps) {
  return (
    <Modal scrollBehavior="inside" size="4xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Otos Health Terms of Use
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody pb={6}>
          <Text fontSize={"xl"}>INTRODUCTION</Text>
          <br />
          <Text>
            This website (referred to in these user terms as the <b>Website</b>)
            and the Otos Health application (<b>Application</b>) are owned and
            operated by{" "}
            <b>
              Tadj Pty Ltd (ABN 53 131 634 270) t/as Otos Health (“Otos Health”)
            </b>
            , who is referred to in these Terms of Use as “we”, “us”, “our” and
            similar grammatical forms. “You”, “your” and other similar
            grammatical forms refers to any person who may use the Website or
            the Application from time to time.
          </Text>
          <br />
          <Text>
            The material on the Website is copyright © 2023 Otos Health.
          </Text>
          <br />
          <Text>
            Otos Health offers secure cloud-based services (<b>Services</b>)
            that allow the safe collection, use and disclosure of individuals’
            personal information (which includes health information) in order
            to:
          </Text>
          <br />
          <UnorderedList pl={4}>
            <ListItem>
              Streamline general practitioners’ (<b>“GPs”</b>) consultations
              with their patients prior to their appointment;
            </ListItem>
            <ListItem>
              Provide GPs a secure, speech to text narration platform with
              integrated artificial intelligence and machine learning to assist
              in preparing consultation summaries and referral letters; and
            </ListItem>
            <ListItem>
              Offer GPs decision support when providing clinical notation
              summary, including suggesting alternative diagnoses and management
              for its users’ consideration.
            </ListItem>
          </UnorderedList>
          <br />
          <Text>
            Users may use the Website and the Application (<b>Platforms</b>) in
            accordance with these Terms of Use.
          </Text>
          <br />
          <Text>
            For the purposes of these Terms of Use, the following meanings
            apply:
          </Text>
          <br />
          <Text>
            <b>‘Users’</b> means any person or entity who engages us to provide
            them with Services by using the Website or Application.
          </Text>
          <br />
          <Text>
            <b>‘Content’</b> has the meaning set out under clause 1(b).
          </Text>
          <br />
          <Text>
            <b>‘GST’</b> has the same meaning as defined in the A New Tax System
            (Goods and Services Tax) Act 1999 (Cth).
          </Text>
          <br />
          <Text>
            <b>‘Services’</b> has the meaning set out above.
          </Text>
          <br />
          <Text>The Platforms are available for Users to:</Text>
          <br />
          <Text pl={4}>
            (a) Access and use, conditional on your acceptance without
            alteration of the terms and conditions set out below. By continuing
            to access and/or use the Platforms, you are agreeing to these Terms
            of Use; and Upload material or information conditional on your
            acceptance without alteration of the terms and conditions set out
            below. By continuing to provide upload material or information to
            the Platforms you are agreeing to these Terms of Use.
          </Text>
          <br />
          <Text pl={4}>
            (b) When you accept these Terms of Use on behalf of an employer, end
            user, or institution, you represent and warrant that you are
            authorised to do so by such entity by which you are employed or
            retained and for whose benefit you are using the Services.
            References herein to “you” and “your” shall mean “you and/or your
            employer, end user and your institution.”
          </Text>
          <br />
          In the interpretation of these Terms of Use, the following provisions
          apply unless the context otherwise requires:
          <OrderedList pl={4}>
            <ListItem>
              the singular includes the plural and vice versa;
            </ListItem>
            <ListItem>
              a reference to any legislation includes any statutory modification
              or re-enactment of, and any subordinate legislation issued under,
              that legislation or legislative provision;
            </ListItem>
            <ListItem>
              a reference to an individual or person includes a corporation,
              partnership, joint venture, association, authority, trust, state
              or government and vice versa;
            </ListItem>
            <ListItem>a reference to any gender includes all genders;</ListItem>
            <ListItem>
              a reference to a recital, clause, schedule, annexure or exhibit is
              to a recital, clause, schedule, annexure or exhibit of or to these
              Terms of Use;
            </ListItem>
            <ListItem>
              a reference to any party to these Terms of Use or any other
              document or arrangement includes that party’s executors,
              administrators, substitutes, successors, and permitted assigns;
            </ListItem>
            <ListItem>
              a reference to dollars or $ means the lawful currency of
              Australia;
            </ListItem>
            <ListItem>
              where an expression is defined, another part of speech or
              grammatical form of that expression has a corresponding meaning;
              and headings are for ease of reference only and do not affect
              interpretation;
            </ListItem>
            <ListItem>
              a rule of construction does not apply to the disadvantage of a
              party because the party was responsible for the preparation of
              these Terms of Use, or any part of it; and
            </ListItem>
            <ListItem>
              unless expressly stated to be otherwise, the meaning of general
              words is not limited by specific examples introduced by
              “including”, “for example” or similar inclusive expressions.
            </ListItem>
          </OrderedList>
          <br />
          <Text fontSize="xl">
            <b>
              GENERAL TERMS OF ACCESS TO AND USE OF THE PLATFORMS (TERMS OF
              ACCESS)
            </b>
          </Text>
          <br />
          <OrderedList>
            <ListItem>
              <Text fontSize="l" fontWeight="bold">
                Use of the Platforms
              </Text>

              <Text>
                <Text>
                  (a) You may not use the Platforms (or the material contained
                  on it) for any illegal purpose. This includes:
                </Text>

                <Text pl={4}>
                  <Text>
                    (i) the reproduction of the material in any material form;
                  </Text>

                  <Text>
                    (ii) the distribution of the material in any material form;
                  </Text>

                  <Text>
                    (iii) re-transmission of the material by any medium of
                    communication;
                  </Text>

                  <Text>
                    (iv) uploading or reposting the material to any other site
                    on the internet; and
                  </Text>

                  <Text>
                    (v) “framing” the material on the Platforms with other
                    material on any other website.
                  </Text>

                  <Text>
                    The above are unlawful in any jurisdiction and are
                    specifically prohibited by these Terms of Access.
                  </Text>
                </Text>

                <Text>
                  (b) Despite the above restrictions on use of the material on
                  the Platforms, and subject to your continued compliance with
                  these Terms of Use (and in consideration of your payment of
                  our fees), we grant you a limited, non-exclusive,
                  non-transferable, non-sublicenceable, revocable right to
                  access and use the Services (including access to certain
                  content available on the Platforms (“Content”)) solely in
                  accordance with the terms set out in these Terms of Use.
                </Text>
                <Text>
                  (c) You may download material from the Platforms for your use
                  provided you do not remove any copyright and trademark notices
                  contained on the material.
                </Text>
                <Text>(d) You may not modify or copy:</Text>

                <Text pl={4}>
                  <Text>(i) the layout of the Platforms; or</Text>

                  <Text>
                    (ii) any computer software and code contained in the
                    Platforms.
                  </Text>
                </Text>

                <Text>
                  (e) You may only use the Services in accordance with these
                  Terms of Use, any directions given by us (acting reasonably),
                  and with all local and foreign laws and regulations applicable
                  to you, and for lawful purposes.
                </Text>

                <Text>
                  (f) You must not, nor cause or permit any other person to:
                </Text>

                <Text pl={4}>
                  <Text>
                    (i) use the Services in a manner or for a purpose which is
                    improper, immoral or fraudulent, which infringes any
                    person’s rights (including intellectual property rights or
                    privacy rights), violates these Terms of Use, or which
                    restricts or interferes with the provision of the Services
                    by us to any other user;
                  </Text>
                  <Text>
                    (ii) alter, modify, adapt or copy the whole or any part of
                    the Services;
                  </Text>
                  <Text>
                    (iii) reproduce, duplicate, sell, exploit, decompile,
                    dissemble, reverse engineer, enhance, alter or otherwise
                    interfere with the whole or any part of the Services;
                  </Text>
                  <Text>
                    (iv) remove or obscure any copyright, trademark or other
                    proprietary notice on the Services;
                  </Text>
                  <Text>
                    (v) enter into any transaction relating to access or use of
                    the Services with a party other than us, without our prior
                    written consent;
                  </Text>
                  <Text>
                    (vi) use any part of the Services or the Platforms to aid
                    any person or entity that conducts business which is the
                    same or substantially similar to our business (being the
                    business of providing billing and administrative services to
                    medical practitioners);
                  </Text>
                  <Text>
                    (vii) attempt to disable or circumvent any security or other
                    technological measure designed to protect the Services or
                    users;
                  </Text>
                  <Text>
                    (viii) attempt to gain unauthorised access to the Services,
                    computer systems or networks connected to the Services,
                    through hacking, password mining or any other means; or
                  </Text>
                  <Text>
                    (ix) use the Services for any purpose or activity of an
                    illegal, fraudulent, obscene, offensive, personally
                    offensive or defamatory nature or use any mechanism, device,
                    software or script to affect the proper functioning of the
                    Services.
                  </Text>
                </Text>
                <Text>(g) At all times you must:</Text>
                <Text pl={4}>
                  <Text>(i) be honest and faithful in all your dealings;</Text>
                  <Text>
                    (ii) not engage in any unsound, unethical or improper
                    business;
                  </Text>
                  <Text>
                    (iii) provide full and correct information to us, and
                    provide truthful explanations to us, in all matters relating
                    to these Terms of Use;
                  </Text>
                  <Text>
                    (iv) ensure at all times that your conduct does not bring
                    any discredit on us or cause any nuisance or disruption to
                    us or our employers;
                  </Text>
                  <Text>
                    (v) immediately notify us if you become aware of any
                    malfunction of the Service or any breach of these Terms of
                    Use.
                  </Text>
                </Text>
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize="l" fontWeight="bold">
                Registration
              </Text>

              <Text>
                (a) In order for us to provide certain functions of the
                Services, you must complete your registration details as
                described on the Services and agree to these Terms of Use.
              </Text>
              <Text>
                (b) At the time of registration, you must nominate an email
                address and password.
              </Text>
              <Text>
                (c) You agree to:
                <Text pl={4}>
                  <Text>
                    (i) keep your password confidential and not disclose it to
                    any other person;
                  </Text>
                  <Text>
                    (ii) ensure that your registration details are true and
                    correct at all times and must notify us of any change to
                    your registration details as originally supplied; and
                  </Text>
                  <Text>
                    (iii) immediately notify us of any unauthorised use of your
                    account or of password or any other breach of security.
                  </Text>
                </Text>
              </Text>
              <Text></Text>

              <Text>
                (d) You are solely responsible for selecting and providing
                access to authorised users under your account and for any use of
                the Services by your account (even by unauthorised users).
              </Text>
              <Text>
                (e) You will select, supervise, monitor, and train the
                authorised users who use the Services to ensure the proper use
                of the Services, compliance with these Terms of Use, and the
                security of the information maintained in the Services.
              </Text>
              <Text>
                (f) You acknowledge and agree that we are not responsible for
                the selection, supervision, monitoring, or training of your
                authorised users. You are responsible for terminating access to
                the Services for individuals who are no longer authorised to use
                the Services in connection with your account.
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize="l" fontWeight="bold">
                Links to other websites
              </Text>
              <Text>
                (a) The Platforms contain links to sites on the internet owned
                and operated by third parties and which are not under our
                control.
              </Text>
              <Text>
                (b) In relation to the other sites on the internet, which are
                linked on the Platforms, we:
                <Text pl={4}>
                  <Text>
                    (i) provide the links to other sites as a convenience to you
                    and the existence of a link to other sites does not imply
                    any endorsement by us of the contents of the linked site;
                    and
                  </Text>

                  <Text>
                    (ii) are not responsible for the material contained on those
                    linked sites.
                  </Text>
                </Text>
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize="l" fontWeight="bold">
                Disclaimers and limitations of liability
              </Text>
              <Text>
                (a) We are an agent in the relationship between you and other
                users, and any information we make available to you is provided
                by third parties and does not originate from us. We do not
                independently verify the information provided by third parties,
                including information provided by any other user, and pass on
                information to you with no representation or warranty regarding
                the accuracy, truthfulness, usefulness, reliability or integrity
                of any information that has not expressly originated from us.
              </Text>
              <Text>
                (b) We will use reasonable efforts to make the Services
                accessible 24 hours a day, seven days a week or as otherwise
                stated on the Services from time to time, but we do not
                guarantee such availability.
              </Text>
              <Text>
                (c) With respect to the information contained on the Platforms
                or linked sites on the internet, we:
                <Text pl={4}>
                  <Text>
                    (i) are making the Platforms available without assuming a
                    duty of care to users; and
                  </Text>
                  <Text>
                    (ii) are not in the business of providing professional
                    advice,
                  </Text>
                </Text>
                and therefore, to the fullest extent permitted by law, we
                disclaim any and all warranties, guarantees or representations
                (either express or implied) about:
                <Text pl={4}>
                  <Text>
                    (i) the accuracy, reliability, completeness, usefulness,
                    timeliness or otherwise of any information
                  </Text>
                  contained or referred to on the Platforms or of any linked
                  sites; and
                  <Text>
                    (ii) the merchantability or fitness for any particular
                    purpose for any service or product of any information
                    contained or referred to on the Platforms or on any linked
                    sites.
                  </Text>
                </Text>
              </Text>

              <Text>
                (d) We will not be liable under any circumstances for any loss
                of profits or any damages of any kind recognised by law (even if
                it has been advised of the possibility of such loss of profits
                or damages) which are the consequence of you:
                <Text pl={4}>
                  <Text>
                    (i) acting, or failing to act, on any information contained
                    on or referred to on the Platforms or any of the linked
                    websites; and
                  </Text>
                  <Text>
                    (ii) using or acquiring, or your inability to use or
                    acquire, any service or product contained or referred to on
                    the Platforms or any linked sites.
                  </Text>
                </Text>
              </Text>

              <Text>
                (e) We do not warrant, guarantee or make any representation
                that:
                <Text pl={4}>
                  <Text>
                    (i) the Platforms, or the server that makes the Platforms
                    available on the internet are free of software viruses;
                  </Text>
                  <Text>
                    (ii) the functions contained in any software contained on
                    the Platforms will operate uninterrupted or are error-free;
                    and
                  </Text>
                  <Text>
                    (iii) errors and defects in the Platforms will be corrected,
                    and you must make your own precautions to ensure that the
                    process which you use for accessing the Platforms does not
                    expose you to the risk of viruses, malicious computer code
                    or other forms of interference which may damage your own
                    computer system.
                  </Text>
                </Text>
              </Text>

              <Text>
                (f) We are not liable to you for:
                <Text pl={4}>
                  <Text>
                    (i) errors or omissions in the Platforms, the Services, or
                    linked sites on the internet;
                  </Text>
                  <Text pl={4}>
                    <Text>
                      (1) delays to, interruptions of or cessation of the
                      services provided in the Platforms or linked sites;
                    </Text>
                    <Text>
                      (2) any interference or damage to your own computer system
                      which arises in connection with your use of the Services,
                      the Platforms, or linked sites; and
                    </Text>
                    <Text>
                      (3) defamatory, offensive or illegal conduct of any user
                      of the Platforms,
                    </Text>
                    <Text>
                      whether caused through our negligence or the negligence of
                      our employees, independent contractors or agents, or
                      through any other cause.
                    </Text>
                  </Text>
                </Text>
              </Text>

              <Text>
                (g) You agree to accept the full cost of any necessary repair,
                correction and maintenance of any of your computer software or
                hardware, which may be necessary as a consequence of you
                accessing the Platforms.
              </Text>
              <Text>
                (h) Where the Content provided by others contains opinions or
                judgements of third parties, we do not purport to endorse those
                opinions or judgements, nor the accuracy or reliability of them.
              </Text>
              <Text>
                (i) You are responsible for all activities that occur under your
                account on the Services, and you are liable for the acts,
                defaults, and omissions of any person who accesses your account
                on the Services, as if they were acts, defaults, or omissions by
                you.
              </Text>
              <Text>
                (j) Whilst we will undertake reasonable efforts to protect the
                information which we transmit and receive in accordance with our{" "}
                <Link
                  onClick={() => {
                    onClose();
                    setPolicyOpen(true);
                  }}
                >
                  Privacy Policy
                </Link>
                , we do not warrant the security of any information which you
                transmit to us, and you are responsible for ensuring you have
                retained appropriate backups of any Content which you may
                provide to us.
              </Text>
              <Text>
                (k) Our sole liability for breach of any terms, conditions and
                warranties in jurisdictions where such terms, conditions and
                warranties are implied and required by law, where the breach
                relates to the supply of services that are not of a kind
                ordinarily acquired for personal, domestic or household use or
                consumption, and where it is fair and reasonable to do so, is
                limited at our option to one or more of the following:
                <Text pl={4}>
                  <Text>(i) the supplying of the Services again; or</Text>
                  <Text>
                    (ii) the payment of the cost of having the Services supplied
                    again.
                  </Text>
                </Text>
              </Text>

              <Text>
                (l) To the fullest extent permitted by law and except as
                otherwise expressly stated in these Terms of Use, our liability
                to you for any reason (including any breach of, or omission
                under, these Terms of Use) is limited in aggregate to the amount
                of the fees (if any) paid to you by us in respect of that
                calendar month.
              </Text>
              <Text>
                (m) To the fullest extent permitted by law we are not liable for
                any direct, indirect, incidental, special and/or consequential
                damage, loss, claim, expense or loss of profits, economic loss,
                business interruption, loss of goodwill, loss of profits, loss
                of savings on overheads or any loss of data which result from
                any use or access, or any inability to use or access, or misuse
                by you or any other party, of the Services or otherwise in
                connection with any content, even if we have been advised of the
                possibility of such damages or loss.
              </Text>
              <Text>
                (n) Nothing in these Terms of Use is intended to exclude or
                limit any condition, warranty, right, or liability in a manner
                that is not permissible under applicable law. In some
                circumstances, some jurisdictions may not permit the exclusion
                of certain warranties or conditions, the exclusion of incidental
                or consequential damages, or the limitation or exclusion of
                liability for loss or damage caused by negligence, breach of
                contract, or breach of implied terms. Accordingly, only the
                above limitations which are lawful in the applicable
                jurisdiction in which you may use the Services. With respect to
                any limitations which are not lawful as stated, our liability
                will be limited to the maximum extent permitted by applicable
                law.
              </Text>
              <Text>
                (o) Subject to this clause 4, our liability for loss or damage
                to you will be reduced proportionately to the extent that:
                <Text pl={4}>
                  <Text>
                    (i) such loss or damage has been caused by your failure to
                    comply with your obligations and responsibilities under
                    these Terms of Use; or
                  </Text>
                  <Text>
                    (ii) your actions or omissions have contributed to such loss
                    or damage regardless of whether a claim is made by the other
                    party.
                  </Text>
                </Text>
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize="l" fontWeight="bold">
                Termination of access
              </Text>

              <Text>
                (a) Your agreement with us commences on the date you
                successfully register on the Platforms or commence using our
                Services, and remains in force until terminated in accordance
                with this Terms of Use or as otherwise stipulated by us in
                writing.
              </Text>
              <Text>
                (b) We reserve the right from time to time to immediately alter,
                interrupt, suspend, or terminate your right to access the whole
                or any part of the Services (including your access to our
                Platforms) at any time in our sole discretion and without giving
                any explanation, justification or notice for the termination of
                access.
              </Text>
              <Text>
                (c) Without limiting our rights under clause 5(b) above, we may
                also immediately terminate your right to access the whole or any
                part of the Services (including your access to our Platforms)
                without any notice to you if you are in breach of these Terms of
                Use at any time.
              </Text>
              <Text>
                (d) On termination, you must immediately pay any and all
                outstanding invoices or any charges or fees incurred as a result
                of your access and use of the Service before the date of
                termination.
              </Text>
              <Text>
                (e) You agree that we will not be liable for any costs, losses
                or damages of any kind that may arise from any such
                interruption, suspension, termination or alteration pursuant to
                this clause.
              </Text>
              <Text>
                (f) Termination does not affect any accrued rights or
                liabilities of either you or us, nor does it affect any
                provision which is expressly or by implication intended to
                operate after termination.
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize="l" fontWeight="bold">
                Alteration of Terms of Access
              </Text>

              <Text>
                (a) We reserve the right to change these Terms of Access:
                <Text pl={4}>
                  <Text>(i) with or without further notice to you; and</Text>
                  <Text>
                    (ii) without giving you any explanation or justification for
                    such change.
                  </Text>
                </Text>
              </Text>

              <Text>
                (b) Amendments will be effective immediately upon notification
                on the Platforms. Your continued use of the Platforms will
                represent an agreement by you to be bound by the Terms of Use as
                amended.
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize="l" fontWeight="bold">
                Use and disclosure of personal information
              </Text>
              <Text>
                <Text>
                  (a) We and any people or legal entities authorised by us
                  (including your GP) may collect, use and process the personal
                  information (including via the use of artificial
                  intelligence):
                </Text>
                <Text pl={4}>
                  <Text>
                    (i) which you may provide when accessing the Platforms, such
                    as your name, address, e-mail address, health information,
                    and other personal or sensitive information about you; and
                  </Text>

                  <Text pl={4}>
                    (1) regarding the way in which you use the Website
                    including, without limitation, information acquired through
                    the use of cookies delivered to your computer when you
                    access our Website.
                  </Text>
                </Text>
              </Text>
              <Text>
                (b) We may authorise others to offer you goods and services
                using the information acquired, as described in clause 7(a).
              </Text>
              <Text>
                (c) We will comply with the Australian privacy legislation with
                respect to our collection, storage, use and disclosure of your
                personal information. Please refer to our full{" "}
                <Link
                  onClick={() => {
                    onClose();
                    setPolicyOpen(true);
                  }}
                >
                  Privacy Policy
                </Link>{" "}
                for details of how we collect, store, use and disclose your
                personal information.
              </Text>
              <Text>
                (d) Upon the completion of the Services, we will use our
                reasonable endeavours to permanently delete any of your personal
                information which we may hold, subject to any obligations we may
                have under applicable privacy legislation to retain such
                personal information.
              </Text>
              <Text>
                <Text>
                  (e) You will at all times indemnify and keep indemnified us
                  and our respective officers, employees, contractors, agents
                  and other representatives (Those Indemnified) from and against
                  any loss (including reasonable legal costs and expenses) or
                  liability incurred by any of Those Indemnified arising from
                  any claim, demand, suit, action or proceeding by any person
                  against any of Those Indemnified where such loss or liability
                  arose out of, in connection with or in respect of:
                </Text>
                <Text pl={4}>
                  <Text>
                    (i) your use of the Services (including but not limited to
                    your access and use of the Platforms and the Content);
                  </Text>
                  <Text>(ii) any breach of these Terms of Use by you; and</Text>
                  <Text>
                    (iii) our collection, use, publication or distribution of
                    the material or information supplied by you from time to
                    time.
                  </Text>
                </Text>
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize="l" fontWeight="bold">
                Intellectual property rights
              </Text>

              <Text>
                <Text>
                  (a) We reserve all intellectual property rights, including,
                  but not limited to, copyright in the Content, the Platforms,
                  and any material or services provided by it. The Content is
                  provided to you in accordance with the licence set out under
                  clause 1(b) above only and may not be:
                </Text>
                <Text pl={4}>
                  (i) re-sold or re-distributed in any material form; (ii)
                  stored in any storage media; or (iii) re-transmitted in any
                  media,
                </Text>
                <Text>without our prior written consent.</Text>
              </Text>

              <Text>
                (b) We reserve the right to modify, vary, correct, alter, remove
                or delete any part or all of the Platforms or the Content, at
                any time in our sole discretion and without any notice to you.
                We and our respective officers, employees and agents have no
                liability for any costs, losses or damages of any kind, which
                you may incur, arising whether directly or indirectly as a
                consequence of removing any material or information from the
                Platforms.
              </Text>
              <Text>
                (c) All logos, icons, brand names or service names that
                identifying the owner and operator of the Platforms are our
                copyright property or our trade marks or service marks. All
                other trade marks or service marks on the Platforms or any
                linked websites are the property of their respective owners. You
                must obtain written permission before reusing any copyrighted
                material that is published on the Platforms. Any unauthorised
                use of the materials appearing on the Platforms may violate
                copyright, trade mark and other property rights or legal
                protections and could result in criminal and/or civil penalties.
              </Text>
              <Text>
                (d) Other than for the purposes of, and subject to the
                conditions prescribed under applicable copyright laws which
                apply in your location, and except as expressly authorised by
                these Terms of Use, you may not in any form or by any means
                without our prior written approval:
                <Text pl={4}>
                  <Text>
                    (i) adapt, reproduce, distribute, print, display, perform,
                    publish or create derivative works from any part of the
                    Services; or
                  </Text>
                  <Text>
                    (ii) commercialise any Content, goods or services obtained
                    from any part of the Services.
                  </Text>
                </Text>
              </Text>

              <Text>
                (e) You guarantee and warrant that you hold all intellectual
                property rights in the Content which you upload to the Platforms
                and/or have obtained any relevant consents which may be required
                under applicable privacy legislation for the use and disclosure
                of such information (and you indemnify us from any damages,
                costs, losses or liabilities which may arise from our use of the
                Content). By submitting any information or other material to us
                (including inputting data or engaging in any other form of
                communication), you grant to us a perpetual, royalty-free,
                non-exclusive, irrevocable, unrestricted, worldwide licence to
                do the following in respect of the information or material:
              </Text>
              <Text pl={4}>
                <Text>
                  (i) for the purpose of complying with our obligations under,
                  and to permit you to comply with all of your obligations
                  under, these Terms of Use;
                </Text>
                <Text>
                  (ii) use, copy, sublicence, redistribute, edit, adapt,
                  transmit, publish and/or broadcast, publicly perform or
                  display; and
                </Text>
                <Text>
                  (iii) sublicence to any third parties the unrestricted right
                  to exercise any of the rights granted in paragraph (ii),
                  provided that, where such use is not for the purpose of
                  complying with these Terms of Use, all personal and sensitive
                  information will be removed from the information and materials
                  before such information and/or materials is made available to
                  any other person.
                </Text>
              </Text>
              <Text>
                (f) You agree that the licence provided to us under clause 8(e)
                above includes the right to exploit all proprietary rights in
                that information or other material including but not limited to
                rights under copyright, trademark, service mark or patent laws
                under any jurisdiction worldwide.
              </Text>
              <Text>
                (g) You unconditionally and irrevocably consent to all acts and
                omissions by us, or people authorised by us, which would
                otherwise amount to an infringement of your moral rights in that
                information or other material. This includes consent to change
                the information or other material even if the change amounts to
                a “derogatory treatment” of the information or other material as
                that term is defined in Division 4 of Part 9 of the Copyright
                Act 1968 (Cth).
              </Text>
              <Text>
                (h) At our request and expense, you will execute and deliver to
                us such instruments and take such other actions as may be
                required to give full legal effect to this grant of licence and
                consent.
              </Text>
              <Text>
                (i) You are solely responsible for retaining/maintaining/storing
                and backing up (electronically and/or with hard copies) any
                information or materials that you wish to preserve. We are not
                responsible for unauthorised access to, use of or alteration of
                your information.
              </Text>
              <Text>
                (j) To the extent any moral rights are not transferable or
                assignable, you hereby waive and agree never to assert any and
                all moral rights, or to support, maintain or permit any action
                based on any moral rights that you may have in or with respect
                to any information or materials you upload to or through the
                Platforms. You expressly release us and our agents, partners,
                licensees, successors, and assigns from any and all claims,
                demands, liabilities, or causes of action, whether now known or
                unknown, for defamation, copyright infringement, violation of
                moral rights, and invasion of the rights to privacy, publicity,
                or personality or any similar matter, or based upon or relating
                to your posting of information or materials.
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize="l" fontWeight="bold">
                Relevant jurisdiction
              </Text>

              <Text>
                (a) If any part of these Terms of Access is found to be void,
                unlawful, or unenforceable then that part will be deemed to be
                severable from the balance of these Terms of Access and the
                severed part will not affect the validity and enforceability of
                any remaining provisions.
              </Text>
              <Text>
                (b) These Terms of Access will be governed by and interpreted in
                accordance with the law of Western Australia, without giving
                effect to any principles of conflicts of laws.
              </Text>
              <Text>
                (c) You agree to the jurisdiction of the courts of Western
                Australia to determine any dispute arising out of these Terms of
                Access.
              </Text>
            </ListItem>
          </OrderedList>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme={"brand"}
            onClick={onClose}
            rightIcon={<FontAwesomeIcon icon={["fas", "check"]} />}
          >
            Continue with form
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
