import React from "react";
import { Textarea } from "@chakra-ui/textarea";
import { IMedspecField } from "@packages/types";
import { useFormState } from "contexts/FormState";

import { useValidation } from "../../hooks/useValidation";

import { fieldId } from "./fieldId";

export function ParagraphField({
  id,
  name,
  validators,
  placeholder,
}: IMedspecField): React.ReactElement {
  // Context for storing form information and validity states
  const { form, setFormState } = useFormState();
  const { isInvalid } = useValidation(id, validators);

  const handleUpdate = (newValue: string): void => {
    setFormState((prev) => ({ ...prev, [id]: newValue }));
  };

  return (
    <Textarea
      id={`${fieldId({ name, id })}`}
      placeholder={placeholder}
      value={form[id] ?? ""}
      data-is-invalid={isInvalid}
      onChange={(event) => handleUpdate(event.target.value)}
      isInvalid={isInvalid}
    />
  );
}
