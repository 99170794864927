import React from "react";

export interface IFormStateContext {
  form: {
    [key: string]: any;
  };
  setFormState: (newState: object) => void;
}

const FormStateContext = React.createContext<IFormStateContext | null>(null);

export function useFormState(): IFormStateContext {
  const context = React.useContext(FormStateContext);
  if (context === undefined) {
    throw new Error("useFormState must be used within an FormStateProvider");
  }
  return context as IFormStateContext;
}

export function FormStateProvider({ children }) {
  const [form, setFormState] = React.useState({});
  return (
    <FormStateContext.Provider value={{ form, setFormState }}>
      {children}
    </FormStateContext.Provider>
  );
}
