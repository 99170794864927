import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { FieldTypes, IMedspecField } from "@packages/types";

import { ConditionalField } from "./Conditional";
import { DatePickerField } from "./Date";
import { DropdownField } from "./Dropdown";
import { fieldId } from "./fieldId";
import { MultiChoiceField } from "./Multiselect";
import { NumberField } from "./Number";
import { ParagraphField } from "./Paragraph";
import { TextField } from "./Text";

function InputField({ field }: { field: IMedspecField }) {
  switch (field.type) {
    case FieldTypes.Text:
      if (field?.choices?.length) {
        return <DropdownField {...field} />;
      }
      return <TextField {...field} />;

    case FieldTypes.Number:
      return <NumberField {...field} />;
    case FieldTypes.DateTime:
      return <DatePickerField {...field} />;
    case FieldTypes.MultiSelect:
      return <MultiChoiceField {...field} />;
    case FieldTypes.Conditional:
      return <ConditionalField {...field} />;
    case FieldTypes.Paragraph:
      return <ParagraphField {...field} />;
    default:
      return <p>ERROR</p>;
  }
}

export default function FormField({ field }: { field: IMedspecField }) {
  return (
    <FormControl mt={5} isRequired={field.validators.length > 0}>
      <FormLabel htmlFor={`${fieldId(field)}`}>{field.name}</FormLabel>
      <InputField field={field} />
    </FormControl>
  );
}
