import React from "react";
import { IForm } from "@packages/types";

export interface ISchemaStateContext {
  schema: IForm | undefined;
  setSchemaState: (schema: IForm | undefined) => void;
}

const SchemaStateContext = React.createContext<ISchemaStateContext | null>(
  null,
);

export function useSchemaState(): ISchemaStateContext {
  const context = React.useContext(SchemaStateContext);
  if (context === undefined) {
    throw new Error(
      "useSchemaState must be used within an SchemaStateProvider",
    );
  }
  return context as ISchemaStateContext;
}

export function SchemaStateProvider({ children }) {
  const [schema, setSchemaState] = React.useState<IForm | undefined>(undefined);
  return (
    <SchemaStateContext.Provider value={{ schema, setSchemaState }}>
      {children}
    </SchemaStateContext.Provider>
  );
}
