import React from "react";

interface IPrefetchRecords {
  [key: string]: boolean;
}

interface IPrefetchStateContext {
  prefetchedSlides: IPrefetchRecords;
  setPrefetchedSlides: (value: IPrefetchRecords) => void;
}

const PrefetchStateContext = React.createContext<IPrefetchStateContext | null>(
  null,
);

export function usePrefetched(): IPrefetchStateContext {
  const context = React.useContext(PrefetchStateContext);
  if (context === undefined) {
    throw new Error(
      "usePrefetched must be used within an PrefetchStateProvider",
    );
  }
  return context as IPrefetchStateContext;
}

export function PrefetchStateProvider({ children }) {
  const [prefetchedSlides, setPrefetchedSlides] = React.useState({});
  return (
    <PrefetchStateContext.Provider
      value={{ prefetchedSlides, setPrefetchedSlides }}
    >
      {children}
    </PrefetchStateContext.Provider>
  );
}
