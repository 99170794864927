import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
export interface IModalProps {
  isOpen: boolean;
  onClose: () => void;
  name?: string;
  children?: React.ReactNode;
}
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function PrivacyPolicyModal({ isOpen, onClose }: IModalProps) {
  return (
    <Modal scrollBehavior="inside" size="4xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Privacy Policy
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody pb={6}>
          <strong>Introduction</strong>
          <br />
          This privacy policy is to provide information to you, our client, on
          how your personal information (which includes your health information)
          is collected by the Otos Health software.
          <br />
          <br />
          <strong>Why and when your consent is necessary</strong>
          <br />
          When utilize Otos Health software, you provide consent for Otos Health
          to access and use your personal information so we can help you achieve
          the best possible healthcare.
          <br />
          <br />
          <strong>
            Why do we collect, use, hold and share your personal information?
          </strong>
          <br />
          Otos Health collects your personal information to facilitate the
          secure and convenient transmission of your information in electronic
          format your general practice, Pioneer Health Albany. This electronic
          transfer of your information is designed to allow your general
          practice to provide with improved quality, safety and efficiency of
          health care. Once your information has been made available to your
          general practice for a period of 24 hours, Otos Health will
          permanently delete your information from our electronic storage. Your
          information will NOT be made available to any other parties by Otos
          Health.
          <br />
          <br />
          <strong>What personal information do we collect?</strong>
          <br />
          The information we will collect about you includes your:
          <Box pl={5}>
            <ul className="list-disc">
              <Box pl={5}>
                <li>names, date of birth, addresses, contact details</li>
                <li>
                  medical information including medical history, medications,
                  allergies, adverse events, immunisations, social history,
                  family history and risk factors
                </li>
              </Box>
            </ul>
          </Box>
          <br />
          <strong>Dealing with us anonymously</strong>
          <br />
          You have the right to deal with us anonymously or under a pseudonym
          unless it is impracticable for us to do so or unless we are required
          or authorised by law to only deal with identified individuals.
          <br />
          <br />
          <strong>How do we collect your personal information?</strong>
          <br />
          Otos Health collects your personal information via a web application
          that you can access via and SMS link. This information is then
          securely stored temporarily on cloud severs located within Australia,
          and is then made available to be accessed by your general practice.
          Once your general practice has had the opportunity to access your
          provided information, your information is permanently deleted from
          Otos Health servers. In some circumstances your personal information
          may also be collected form other sources, because it may not be
          practical or reasonable to collect it from you directly. This may
          include information from your guardian or responsible person.
          <br />
          <br />
          <strong>
            When, why and with whom do we share your personal information?
          </strong>
          <br />
          We only share your personal information with your nominated general
          practice (Pioneer Health Albany). Once your general practice has had
          the opportunity to access your information, Otos Health will
          permanently delete your information from its server. This deletion of
          information generally occurs every 24 hours. We will not share your
          personal information with any other parties.
          <br />
          <br />
          <strong>
            How do we store and protect your personal information?
          </strong>
          <br />
          Your personal information will be stored in a secure electronic format
          by Otos Health. All Otos Health contractors have signed
          confidentiality agreements. Once your information has been made
          available to your nominated general practice, it will be permanently
          deleted from Otos Health servers.
          <br />
          <br />
          <strong>
            How can you lodge a privacy-related complaint, and how will the
            complaint be handled at our practice?
          </strong>
          <br />
          We take complaints and concerns regarding privacy seriously. You
          should express any privacy concerns you may have in writing. We will
          then attempt to resolve it in accordance with our resolution
          procedure. Complaints should be addressed to the Director, Otos
          Health, 46 Melville Street, Albany WA 6330 or david@dftadj.com.
          Alternatively, you can call Otos Health on 0408428416. We will respond
          with acknowledgement of your complaint within 3 business days and have
          your complaint fully investigated within 30 business days. You may
          also contact the Office of the Australian Information Commissioner
          (OAIC). Generally, the OAIC will require you to give them time to
          respond before they will investigate. For further information visit
          www.oaic.gov.au or call the OAIC on 1300 363 992.
          <br />
          <br />
          <strong>Privacy and our website</strong>
          <br />
          If you “like” or comment on our Facebook page, we will have your
          social media name. Diagnostic information is collected via our
          website, with all other forms of information not being collected
          unless explicitly provided by yourself (e.g. your email address).
          <br />
          <br />
          <strong>Data breach</strong>
          <br />
          In the event of a privacy breach, Otos Health will respond in
          accordance with our Data Breach Response Plan.
          <br />
          <br />
          <strong>Policy review statement</strong>
          <br />
          This privacy policy will be reviewed regularly to ensure it is in
          accordance with any changes that may occur.
          <br />
          <br />
          <strong>Procedure:</strong>
          <br />
          <Text my={1} fontWeight="bold" fontSize="lg">
            Otos Health will:
          </Text>
          <Box pl={5}>
            <ul className="list-disc">
              <Box pl={5}>
                <li>provide a copy of this policy upon request</li>
                <li>
                  ensure contractors and staff comply with the policy and deal
                  appropriately with inquiries or concerns
                </li>
                <li>
                  take such steps as are reasonable in the circumstances to
                  implement practices, procedures and systems to ensure
                  compliance with the policy and deal with inquiries or
                  complaints
                </li>
                <li>
                  collect personal information for the primary purpose of
                  transferring information to the patient’s nominated general
                  practice.{" "}
                </li>
              </Box>
            </ul>
          </Box>
          <br />
          <br />
          <strong>
            Otos Health contractors and staff will take reasonable steps to
            ensure clients understand:
          </strong>
          <br />
          <Box pl={5}>
            <ul className="list-disc">
              <Box pl={5}>
                <li>what information has been and is being collected</li>
                <li>why the information will be used or disclosed</li>
                <li>why and when their consent is necessary</li>
                <li>
                  Otos Health’s procedures for access and correction of
                  information, and responding to complaints of information
                  breaches, including this privacy policy.
                </li>
              </Box>
            </ul>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme={"brand"}
            onClick={onClose}
            rightIcon={<FontAwesomeIcon icon={["fas", "check"]} />}
          >
            Continue with form
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
