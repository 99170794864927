import { FieldTypes } from "@packages/types";
import { AxiosPromise } from "axios";

import { apiClient } from "../client";

export interface IKVPair {
  id: string;
  value: any;
  type?: FieldTypes;
}

export interface IFormProgressResponse {
  progress: number;
  remaining: object;
}

export interface ISlideSaveRequest {
  id: string;
  tenant: string;
  formId: string;
  fields: IKVPair[];
}

export function saveSlide(
  slide: ISlideSaveRequest,
  code: string,
): AxiosPromise<IFormProgressResponse> {
  return apiClient.post(`/data/patient/response/${code}/slides`, slide);
}

export async function getPatient(code: string): Promise<{
  form: string;
  phone: string;
  gender: string;
  firstName: string;
  lastName: string;
  DOB: string;
}> {
  const { data } = await apiClient.get(`/public/patient/${code}`, {
    validateStatus: (s) => s === 404 || s === 200,
  });
  if (!data?.form) {
    throw new Error("404");
  }
  return data;
}

export function prepopulateSlide(
  slideID: string,
  code: string,
): AxiosPromise<any> {
  return apiClient.get(`/data/patient/response/${code}/slides/${slideID}`);
}

export function validateAllSlides(code: string): AxiosPromise<null> {
  return apiClient.post(`/data/patient/response/${code}/validate`);
}
