import { testCondition } from "@packages/api";
import {
  FieldTypes,
  FlowActions,
  IFlowItem,
  IMedspecSlide,
} from "@packages/types";

export function getFieldDefault(fieldType: FieldTypes) {
  switch (fieldType) {
    case FieldTypes.DateTime:
      return null;
    case FieldTypes.Conditional:
      return [];
    case FieldTypes.MultiSelect:
      return [];
    case FieldTypes.Number:
      return 0;
    default:
      return "";
  }
}

// Find and retrieve flow for given slide.
export function getSlideFlow(slideID: string, flow: IFlowItem[]) {
  return flow.find((item) => item.src === slideID);
}

// Determine first form slide based on slide schema.
export function getFirstSlide(
  slides: { [key: string]: IMedspecSlide } | undefined | null,
): [string, IMedspecSlide] | undefined {
  if (slides == null) {
    return;
  }
  return Object.entries(slides).find(
    ([, slide]) => slide?.startingSlide !== undefined && slide?.startingSlide,
  );
}

// Determine the next slide to present the user based on default destination and flow rules.
export function getNextSlide(
  currResponse: { [key: string]: any },
  slideFlow: IFlowItem | undefined,
): string {
  if (!slideFlow) {
    return "";
  }
  // Before testing any rules, check if a redirect action is actually present.
  if (
    slideFlow.rules.some((rule) =>
      rule.then.some((action) => action.action === FlowActions.Redirect),
    )
  ) {
    let currDest = slideFlow.defaultDest;
    // Evaluate each defined rule.
    slideFlow.rules.forEach((rule) => {
      if (rule.then.some((action) => action.action === FlowActions.Redirect)) {
        // Test and ensure every condition is met.
        if (
          rule.conditions.every((condition) => {
            const response = rule.external
              ? currResponse[`${condition.field.split(".")[1]}`]
              : currResponse[`${condition.field}`];
            return testCondition(condition, response, new Date());
          })
        ) {
          // If all conditions met, evaluate redirect actions.
          rule.then.forEach((action) => {
            if (action.action === FlowActions.Redirect) {
              currDest = action.arguments["slide"];
            }
          });
        }
      }
    });
    return currDest;
  } else {
    return slideFlow.defaultDest;
  }
}
