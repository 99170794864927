import { ReactElement, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAlert } from "../contexts/AlertState";
import { useAuthCode } from "../contexts/AuthCode";
import { useFormState } from "../contexts/FormState";
import { useSchemaState } from "../contexts/SchemaState";
import { useGetForm, usePatient, usePrepopulateSlide } from "../http/hooks";
import { getFirstSlide } from "../utils";

export default function AuthCodeNextButton({
  authCode,
}: {
  authCode: string;
}): ReactElement {
  const navigate = useNavigate();

  const { showError } = useAlert();
  const { code, setCode, setPatientInformation } = useAuthCode();

  const { schema, setSchemaState } = useSchemaState();
  const { setFormState } = useFormState();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { data: patient, error: patientFormIDError } = usePatient(code);
  const { data: patientForm, isLoading: patientFormLoading } = useGetForm(
    code,
    patient?.form,
    setSchemaState,
    showError,
  );

  // Prefetch the first slide
  const nextSlide: string | undefined = useMemo(
    () => getFirstSlide(patientForm?.rawSlides)?.[0],
    [patientForm],
  );
  const { data: slideVal } = usePrepopulateSlide(code, nextSlide);
  useEffect(() => {
    if (nextSlide) {
      setFormState((prev) => (prev[nextSlide] = slideVal));
    }
  }, [nextSlide]);

  useEffect(() => {
    if (
      patientFormIDError != null &&
      patientFormIDError?.toString() !== "Error: 404"
    ) {
      showError({
        message:
          "Unable to reach our servers, please check your internet connection.",
        type: "error",
        title: "An Error Occurred",
      });
    }
  });
  useEffect(() => {
    if (patientForm || patientFormIDError) {
      setPatientInformation({
        ...patient,
      });
      setIsLoading(false);
    } else {
      setIsLoading(patientFormLoading);
    }
  }, [patientFormLoading, patientForm, patientFormIDError]);

  const gotoFirstSlide = () => {
    navigate(`/${schema ? getFirstSlide(schema?.slides)?.[0] : ""}`);
  };

  useEffect(() => {
    setCode(authCode);
  }, []);
  return (
    <Button
      data-testid="auth-code-next-button"
      aria-label="auth-code-next-button"
      colorScheme={"brand"}
      isLoading={isLoading}
      isDisabled={!patientForm}
      onClick={gotoFirstSlide}
      rightIcon={<FontAwesomeIcon icon={["fas", "arrow-right"]} />}
    >
      Agree and Continue
    </Button>
  );
}
