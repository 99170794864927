import { Flex } from "@chakra-ui/react";
import { OHIcon } from "@packages/theme/OHIcon";

export default function HeaderBar(): JSX.Element {
  return (
    <Flex align="center" justify="center" bg="white" boxShadow="md" mb={6}>
      <OHIcon className="w-8 my-2" />
    </Flex>
  );
}
