import React from "react";

export interface IError {
  // error , 'warning', 'info', 'success'
  type: "error" | "warning" | "info";
  title: string;
  message: string;
}

export interface IAlertStateContext {
  error: IError | undefined;
  showError: (value: IError | undefined) => void;
}

const AlertStateContext = React.createContext<IAlertStateContext | undefined>(
  undefined,
);

export function useAlert(): IAlertStateContext {
  const context = React.useContext(AlertStateContext);
  if (context === undefined) {
    throw new Error("useAlert must be used within an AlertStateProvider");
  }
  return context as IAlertStateContext;
}

export function AlertStateProvider({ children }) {
  const [error, showError] = React.useState<IError | undefined>(undefined);
  return (
    <AlertStateContext.Provider value={{ error, showError }}>
      {children}
    </AlertStateContext.Provider>
  );
}
