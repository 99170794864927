import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
import { useAlert } from "contexts/AlertState";
import { useSchemaState } from "contexts/SchemaState";
import { FormComplete } from "views/FormComplete";
import InitialNotice from "views/InitialNotice";
import { Slide } from "views/Slide";

import { BaseAlert } from "components/Alerts";
import HeaderBar from "components/HeaderBar";

import InvalidPage from "./views/InvalidPage";

export default function App() {
  // Alerts
  const { error, showError } = useAlert();
  const { schema } = useSchemaState();
  useEffect(() => {
    localStorage.setItem("location", window.location.pathname);
  }, []);
  const renderError = () => {
    if (error) {
      // Set interval to clear the error after 10 sec
      setTimeout(() => showError(undefined), 10000);

      return <BaseAlert error={error} onDismiss={() => showError(undefined)} />;
    }
    return null;
  };

  const showErrorIfExists = () => {
    if (error) {
      return renderError();
    }
    return null;
  };

  const renderBody = () => {
    return (
      <Routes>
        <Route element={<InitialNotice />} path="/i/:code" />
        {schema && (
          <>
            <Route element={<FormComplete />} path="/end" />
            {Object.entries(schema.slides).map(([key, slide]) => (
              <Route
                element={<Slide schema={schema} slide={slide} />}
                path={`/${key}`}
                key={key}
              />
            ))}
          </>
        )}
        <Route
          path="*"
          element={
            <Navigate to={`${localStorage.getItem("location") ?? ""}`} />
          }
        />
        <Route path="/" element={<InvalidPage />} />
      </Routes>
    );
  };

  return (
    <Box minH="100vh" as="main">
      <HeaderBar />
      <Flex
        my="auto"
        justifyContent="space-around"
        flexDirection="column"
        mb={6}
      >
        {showErrorIfExists()}
        {renderBody()}
      </Flex>
    </Box>
  );
}
