import { ReactElement } from "react";
import { Box, Text } from "@chakra-ui/react";

import { Card, CardTitle } from "../components/Card";

export default function InvalidPage(): ReactElement {
  return (
    <>
      <Card>
        <CardTitle>Welcome to Pioneer Health</CardTitle>
        <Box mt={1} style={{ minHeight: 500 }}>
          <Text fontSize="16px" lineHeight="24px">
            Oops! This page doesn't exist.
          </Text>
        </Box>
      </Card>
    </>
  );
}
