import { ReactElement, useEffect, useState } from "react";
import { BiCheckCircle } from "react-icons/bi";
import { Flex, Icon, Text } from "@chakra-ui/react";
import { useAlert } from "contexts/AlertState";
import { useAuthCode } from "contexts/AuthCode";
import { useSchemaState } from "contexts/SchemaState";
import { useValidateAllSlides } from "http/hooks";

import { Card } from "components/Card";
import { LoadingCard } from "components/LoadingCard";

export function FormComplete(): ReactElement {
  useEffect(() => {
    // Add a history event so that the back button go back to login page
    window.history.pushState("back-to-login", document.title, "#/");
  }, []);

  const { schema } = useSchemaState();
  const { code } = useAuthCode();
  const { showError } = useAlert();

  const [alreadySaved, setSaved] = useState<boolean>(false);

  const validateAllSlides = useValidateAllSlides({
    code,
    showError,
    setSaved,
  });

  useEffect(() => {
    validateAllSlides.mutate();
  }, []);

  if (alreadySaved) {
    return (
      <Card>
        <Flex align="center" justify="center">
          <Icon as={BiCheckCircle} mt={8} w={20} h={20} color="green.500" />
        </Flex>

        <Text
          mb={2}
          mt={2}
          fontSize="2xl"
          fontWeight="bold"
          textAlign="center"
          color="gray.800"
        >
          Thanks for filling out this form!
        </Text>
        <Text textAlign="center" mb={6}>
          {schema?.endMessage}
        </Text>
        <Text textAlign="center" mb={6}>
          <b>How was the experience?</b> <br /> We invite you to share your
          thoughts and rate the experience, you can do so by visiting{" "}
          <u>
            <a href="https://forms.office.com/r/brDS6rreyi">this form.</a>
          </u>
        </Text>
      </Card>
    );
  } else {
    return <LoadingCard message="Saving your response..." />;
  }
}
