import { IMedspecValidator, ValidationMethods } from "@packages/types";
import validator from "validator";

// TODO: proper validation of phone number
function validatePhoneNumber(): boolean {
  return true;
}

function validateNonEmpty(value: any): boolean {
  // Returns false if empty
  if (typeof value === "object") {
    if (Array.isArray(value)) {
      return value.length > 0;
    }
  }
  switch (value) {
    case undefined:
      return false;
    default:
      return value.toString().length > 0;
  }
}

function validateEmail(email: string): boolean {
  if (email === undefined) {
    return false;
  } else {
    return validator.isEmail(email);
  }
}

export function getValidator(type: ValidationMethods): (value: any) => boolean {
  switch (type) {
    case ValidationMethods.Email:
      return validateEmail;
    case ValidationMethods.NonEmpty:
      return validateNonEmpty;
    case ValidationMethods.PhoneNumber:
      return validatePhoneNumber;
  }
}

export function validateField<T>(validators: IMedspecValidator[], value: T) {
  // Determines if the field is valid based on the given validators and field value
  let isValid = true;
  validators.forEach((validator) => {
    if (!getValidator(validator.method)(value)) {
      // Looks like field was invalid
      isValid = false;
    }
  });
  return isValid;
}
