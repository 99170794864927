import React from "react";
import { Box, Text } from "@chakra-ui/react";

interface ICardProps {
  children?: React.ReactNode;
}

export function CardTitle({ children }: ICardProps): JSX.Element {
  return (
    <Text mb={2} fontWeight="bold" fontSize="2xl" color="gray.800">
      {children}
    </Text>
  );
}

export function CardFooter({ children }: ICardProps): JSX.Element {
  return (
    <Box h={8} mt={4}>
      {children}
    </Box>
  );
}

export function Card({ children }: ICardProps): JSX.Element {
  return (
    <Box mx={2}>
      <Box
        boxShadow="2xl"
        rounded="md"
        my={"auto"}
        mx={"auto"}
        p={4}
        maxW="xl"
        minW="40%"
        bg="white"
      >
        {children}
      </Box>
    </Box>
  );
}
