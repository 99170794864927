import { ReactElement, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Text } from "@chakra-ui/react";

import Link from "components/Link";
import { PrivacyPolicyModal } from "components/PrivacyPolicyModal";
import { TermsOfUseModal } from "components/TermsOfUseModal";

import AuthCodeNextButton from "../components/AuthCodeNextButton";
import { Card, CardTitle } from "../components/Card";

export default function InitialNotice(): ReactElement {
  const [policyOpen, setPolicyOpen] = useState<boolean>(false);
  const [touOpen, setTouOpen] = useState<boolean>(false);
  const { code } = useParams();
  return (
    <>
      <PrivacyPolicyModal
        isOpen={policyOpen}
        onClose={() => setPolicyOpen(false)}
      />
      <TermsOfUseModal
        isOpen={touOpen}
        setPolicyOpen={setPolicyOpen}
        onClose={() => setTouOpen(false)}
      />
      <Card>
        <CardTitle>Welcome</CardTitle>
        <Box mt={1}>
          <Text fontSize="16px" lineHeight="24px">
            <b>
              At Pioneer Health, we are currently in the process of developing
              new software to enhance patient care at our practice.{" "}
            </b>
            You are hereby invited to participate in trialing this new software.
            Your participation will allow us to make improvements to the
            software and is much appreciated.
          </Text>
          <br />
          <Text fontSize="16px" lineHeight="24px">
            Any of the information you provide through the software will be
            treated as <b>private and confidential</b>. Your information will be
            offered to your doctor for inclusion in your practice electronic
            health record. Once the doctor has had access to your provided
            information, the software <b>will permanently delete all </b>
            the information you provided today. The software will not
            permanently store, any of your provided information.
            <b>
              {" "}
              Other patients will not be able to access your provided
              information.
            </b>
          </Text>
          <br />
          <Text fontSize="16px" lineHeight="24px">
            For more information, please read the Otos Health{" "}
            <Link onClick={() => setPolicyOpen(true)}>Privacy Policy</Link>.
          </Text>
          <br />
          <Text fontSize="16px" lineHeight="24px">
            By continuing, you <b>AGREE</b> to the{" "}
            <Link onClick={() => setTouOpen(true)}>Terms of Use</Link>.
          </Text>
        </Box>
        <Box mt={4} h={8} mb={2} display="flex" justifyContent="flex-end">
          <AuthCodeNextButton authCode={code || ""} />
        </Box>
      </Card>
    </>
  );
}
