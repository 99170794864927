import {
  fetchForm,
  getPatient,
  prepopulateSlide,
  saveSlide,
  validateAllSlides,
} from "@packages/api";
import config from "@packages/config/spa";
import { IForm } from "@packages/types";
import * as Sentry from "@sentry/browser";
import { useMutation, useQuery } from "@tanstack/react-query";
import { IFormStateContext } from "contexts/FormState";

import { IAlertStateContext } from "../contexts/AlertState";
import { ISchemaStateContext } from "../contexts/SchemaState";
import { getFieldDefault, getNextSlide, getSlideFlow } from "../utils";

export const usePatient = (patientId: string) =>
  useQuery<
    {
      form: string;
      phone: string;
      gender: string;
      firstName: string;
      lastName: string;
      DOB: string;
    },
    Error
  >(["patientId", patientId], () => getPatient(patientId), {
    retry: false,
    enabled: !!patientId,
  });

export const useGetForm = (
  authCode: string,
  formId: string | null | undefined,
  setSchemaState: ISchemaStateContext["setSchemaState"],
  showError: IAlertStateContext["showError"],
) => {
  return useQuery<IForm | null, Error>(
    ["forms", formId],
    () => (formId ? fetchForm(formId) : null),
    {
      // The query will not execute until the authCode is defined
      enabled: !!authCode && !!formId,
      onSuccess: (data) => {
        if (formId && data) {
          setSchemaState(data);
        } else {
          showError({
            message: "Failed to load the form. Please try refreshing the page.",
            type: "error",
            title: "An Error Occurred",
          });
        }
      },
      onError: () => {
        showError({
          message: "Failed to load the form. Please try refreshing the page.",
          type: "error",
          title: "An Error Occurred",
        });
      },
    },
  );
};

export const usePrepopulateSlide = (
  authCode: string,
  slide: string | undefined,
) =>
  useQuery<any, Error>(
    ["prepopulateSlide", slide],
    () => slide && prepopulateSlide(slide, authCode),
    { enabled: !!slide },
  );

export const useValidateAllSlides = ({
  code,
  showError,
  setSaved,
}: {
  code: string;
  showError: IAlertStateContext["showError"];
  setSaved: (saved: boolean) => void;
}) =>
  useMutation(() => validateAllSlides(code), {
    onError: (error: any) => {
      Sentry.captureException(error);
      if (error === undefined) {
        showError({
          type: "warning",
          title: "Failed to save your data",
          message: "Please make sure you are connected to the internet.",
        });
      } else {
        showError({
          type: "warning",
          title: "Oh no!",
          message:
            "It looks like our servers are having issues at the moment... Please come back later to finish your form.",
        });
      }
    },
    onSuccess: () => {
      setSaved(true);
    },
  });

export const useSaveSlideMutation = ({
  code,
  slide,
  form,
  schema,
  navigate,
  showError,
}: {
  code: string;
  slide: any;
  form: IFormStateContext["form"];
  schema: IForm;
  navigate: (path: string) => void;
  showError: IAlertStateContext["showError"];
}) =>
  useMutation(
    () =>
      saveSlide(
        {
          id: slide.id,
          fields: slide.fields?.map((field) => ({
            id: field.id,
            value: form[field.id] ?? getFieldDefault(field.type),
            type: field.type,
          })),
          tenant: config.tenant,
          formId: schema.id,
        },
        code,
      ),
    {
      onError: (error: any) => {
        Sentry.captureException(error);
        if (error.response === undefined) {
          showError({
            type: "warning",
            title: "Check your Connection",
            message:
              "We can't reach our servers right now, please check your internet connection.",
          });
        } else {
          showError({
            type: "error",
            title: "Oh no!",
            message:
              "It looks like our servers are having issues at the moment, please try again later.",
          });
        }
      },
      onSuccess: () => {
        const slideFlow = getSlideFlow(slide.id, schema.flow || []);
        const slideKey = Object.keys(schema.slides).find(
          (key) => key === getNextSlide(form, slideFlow),
        );
        // Check if the slide is defined, and if not, navigate to the end
        if (slideKey && slideKey !== "unselected") {
          navigate(`/${slideKey}`);
        } else {
          navigate("/end");
        }
      },
    },
  );
