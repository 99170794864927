import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
} from "@chakra-ui/react";
import { IError } from "contexts/AlertState";

interface IBaseAlertProps {
  error: IError;
  onDismiss: (error: undefined) => void;
}

export function BaseAlert({ error, onDismiss }: IBaseAlertProps) {
  return (
    <Box mx="auto" my={4} w="2/5">
      <Alert status={error.type}>
        <AlertIcon />
        <Box>
          <AlertTitle>{error.title}</AlertTitle>
          <AlertDescription>{error.message}</AlertDescription>
        </Box>
        <CloseButton my="auto" ml="auto" onClick={() => onDismiss(undefined)} />
      </Alert>
    </Box>
  );
}
