import { useEffect } from "react";
import { Select, Stack, Text } from "@chakra-ui/react";
import { IMedspecField } from "@packages/types";
import { useFormState } from "contexts/FormState";
import { getDaysInMonth, parse } from "date-fns";
import { DateTime } from "luxon";

import { useAuthCode } from "../../contexts/AuthCode";
import { useValidation } from "../../hooks/useValidation";

import { fieldId } from "./fieldId";

function renderDays(date: string | undefined): React.ReactNode {
  const daysInMonth = getDaysInMonth(new Date(date || ""));
  const days: Array<number> = [];
  for (let day = 1; day <= daysInMonth; day++) {
    days.push(day);
  }
  return (
    <>
      {days.map((day, index) => (
        <option key={index} value={day}>
          {day}
        </option>
      ))}
    </>
  );
}

function renderYears(limit = 120): React.ReactNode {
  const years: Array<number> = [];
  const current: number = new Date().getFullYear();
  // Generate an array of years between this year and the limit
  for (let year = current - limit; year <= current; year++) {
    years.push(year);
  }
  return (
    <>
      {years.map((year, index) => (
        <option key={index} value={year}>
          {year}
        </option>
      ))}
    </>
  );
}

const MONTHS = [...Array(12).keys()].map((x) => x + 1);

interface IDateParts {
  day?: number;
  month?: number;
  year?: number;
}

const isDOB = (str: string): boolean => {
  const name = str.toLowerCase().replace(/\s/g, "");
  return name.includes("dob") || name.includes("birth");
};

export function DatePickerField({ id, validators, name }: IMedspecField) {
  const { patientInformation } = useAuthCode();
  const today = new Date();

  // Context for storing form information and validity states
  const { form, setFormState } = useFormState();
  const { isInvalid } = useValidation(id, validators);

  const handleUpdate = ({ day, month, year }: IDateParts) => {
    setFormState((prev) => ({
      ...prev,
      [id]: form[id]
        ? form[id].set({ day, month, year })
        : DateTime.fromObject({ day, month, year }),
    }));
  };

  useEffect(() => {
    setFormState((prev) => ({
      ...prev,
      [id]: form[id] ? DateTime.fromISO(form[id]) : DateTime.now(),
    }));
  }, []);
  useEffect(() => {
    if (!form[id]?.day && isDOB(name) && patientInformation.DOB) {
      const dob = parse(patientInformation.DOB, "dd/MM/yyyy", new Date());
      handleUpdate({
        day: dob.getDate(),
        month: dob.getMonth() + 1,
        year: dob.getFullYear(),
      });
    }
  }, [patientInformation]);

  return (
    <Stack direction="row" spacing={1} id={`${fieldId({ name, id })}`}>
      <Select
        name="Day"
        aria-label="Day"
        value={form[id] ? form[id].day : today.getDate()}
        onChange={(e) => handleUpdate({ day: parseInt(e.target.value) })}
        isInvalid={isInvalid}
        data-is-invalid={isInvalid}
      >
        {renderDays(form[id])}
      </Select>
      <Text pl={2} pt={3}>
        /
      </Text>
      <Select
        name="Month"
        aria-label="Month"
        value={form[id] ? form[id].month : today.getMonth()}
        onChange={(event) =>
          handleUpdate({ month: parseInt(event.target.value) })
        }
        isInvalid={isInvalid}
        data-is-invalid={isInvalid}
      >
        {MONTHS.map((month) => (
          <option key={month} value={month} children={month} />
        ))}
      </Select>
      <Text pl={2} pt={3}>
        /
      </Text>
      <Select
        isInvalid={isInvalid}
        data-is-invalid={isInvalid}
        name="Year"
        aria-label="Year"
        value={form[id] ? form[id].year : today.getFullYear()}
        onChange={(event) =>
          handleUpdate({ year: parseInt(event.target.value) })
        }
      >
        {renderYears()}
      </Select>
    </Stack>
  );
}
