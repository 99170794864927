import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import config from "@packages/config/spa";
import sentryInit from "@packages/sentry";

import "@fortawesome/fontawesome-free/js/all";

import { BaseApp } from "./BaseApp";

import "./index.css";

sentryInit({
  dsn: import.meta.env.VITE_SENTRY_DSN_PATIENT_APP,
  release: `${config.version}-${config.commit}`,
});

const container = document.getElementById("root");

if (!container) {
  throw new Error("No app container found");
}

const root = createRoot(container);

root.render(
  <BrowserRouter>
    <BaseApp />
  </BrowserRouter>,
);
