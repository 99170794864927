import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "@packages/theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AlertStateProvider } from "contexts/AlertState";
import { AuthCodeProvider } from "contexts/AuthCode";
import { FormStateProvider } from "contexts/FormState";
import { PrefetchStateProvider } from "contexts/PrefetchState";
import { SchemaStateProvider } from "contexts/SchemaState";
import { ValidationStateProvider } from "contexts/ValidationState";

import App from "./App";

function CustomContexts({ children }) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
  return (
    <SchemaStateProvider>
      <AuthCodeProvider>
        <FormStateProvider>
          <ValidationStateProvider>
            <AlertStateProvider>
              <PrefetchStateProvider>
                <ChakraProvider theme={theme}>
                  <QueryClientProvider client={queryClient}>
                    {children}
                  </QueryClientProvider>
                </ChakraProvider>
              </PrefetchStateProvider>
            </AlertStateProvider>
          </ValidationStateProvider>
        </FormStateProvider>
      </AuthCodeProvider>
    </SchemaStateProvider>
  );
}

export const BaseApp = () => (
  <CustomContexts>
    <App />
  </CustomContexts>
);
