import { useEffect } from "react";

import { useFormState } from "../contexts/FormState";
import { useValidationState } from "../contexts/ValidationState";
import { validateField } from "../functions/validation";

export const useValidation = (id, validators) => {
  const { valid: validFields, setValidationState } = useValidationState();
  const { form } = useFormState();

  useEffect(() => {
    setValidationState(
      (prev: { [k: string]: boolean; emptyForm: boolean }) => ({
        ...prev,
        [id]: validateField<string>(validators, form[id]),
      }),
    );
  }, [form]);
  return {
    isInvalid: !validFields?.emptyForm && !validFields[id],
  };
};
