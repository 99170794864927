import React from "react";

interface IValidationStateContext {
  valid: {
    emptyForm: boolean;
    [key: string]: boolean;
  };
  setValidationState: (
    newState: (prev: { [p: string]: boolean; emptyForm: boolean }) => {
      [p: string]: boolean;
      emptyForm: boolean;
    },
  ) => void;
}

const ValidationStateContext =
  React.createContext<IValidationStateContext | null>(null);

export function useValidationState(): IValidationStateContext {
  const context = React.useContext(ValidationStateContext);
  if (context === undefined) {
    throw new Error(
      "useValidationState must be used within an ValidationStateProvider",
    );
  }
  return context as IValidationStateContext;
}

export function ValidationStateProvider({ children }) {
  const [valid, setValidationState] = React.useState({
    emptyForm: true,
  });
  return (
    <ValidationStateContext.Provider value={{ valid, setValidationState }}>
      {children}
    </ValidationStateContext.Provider>
  );
}
