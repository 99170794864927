import { ReactElement } from "react";
import { NumberInput, NumberInputField } from "@chakra-ui/number-input";
import { IMedspecField } from "@packages/types";
import { useFormState } from "contexts/FormState";

import { useValidation } from "../../hooks/useValidation";

import { fieldId } from "./fieldId";

export function NumberField({
  id,
  name,
  validators,
  placeholder,
}: IMedspecField): ReactElement {
  const { form, setFormState } = useFormState();
  const { isInvalid } = useValidation(id, validators);

  const handleUpdate = (newValue: number): void => {
    setFormState((prev) => ({
      ...prev,
      [id]: isNaN(newValue) ? undefined : newValue,
    }));
  };

  return (
    <NumberInput
      id={`${fieldId({ name, id })}`}
      onChange={(_, numberVal) => handleUpdate(numberVal)}
      isInvalid={isInvalid}
      data-is-invalid={isInvalid}
      placeholder={placeholder}
      value={form[id]}
    >
      <NumberInputField />
    </NumberInput>
  );
}
